.volunteers__section {
  .add__delete__volunteers__section {
    display: flex;
    .delete__volunteers__section {
      margin-right: 25px;
      .volunteer__container {
        display: flex;
        border: 1px solid black;
        height: 50px;
        margin-bottom: 10px;
        align-items: center;
        padding-left: 2%;
        padding-right: 2%;
        justify-content: space-between;
        .delete__volunteer__button {
          button {
            font-size: 18px;
            padding: 5px 10px;
            background-color: red;
            color: white;
            font-weight: bold;
            cursor: pointer;
          }
        }
      }
    }
    .add__volunteers__section {
      padding-left: 50px;
      width: 30%;
      input {
        padding: 8px;
        display: block;
        border: none;
        border-bottom: 1px solid #ccc;
        width: 90%;
        font-size: 16px;
      }
    }
    .submit__volunteers__button {
      button {
        font-size: 22px;
        padding: 10px 15px;
        background-color: #1f6aca;
        color: white;
        font-weight: bold;
        cursor: pointer;
      }
    }
  }
}

.loader {
  border: 12px solid #f3f3f3; /* Light grey */
  border-top: 12px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 12px;
  height: 12px;
  animation: spin 2s linear infinite;
}
