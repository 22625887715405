.delete__press__section {
  .delete__press__container {
    display: flex;
    flex-wrap: wrap;
    .delete__press__tile {
      .delete__press__button {
        margin-bottom: 30px;
        button {
          font-size: 18px;
          padding: 5px 10px;
          background-color: red;
          color: white;
          font-weight: bold;
          cursor: pointer;
        }
      }
    }
  }
  button {
    margin-left: 10px;
    font-size: 22px;
    padding: 10px 15px;
    background-color: #1f6aca;
    color: white;
    font-weight: bold;
    cursor: pointer;
  }
}
