@mixin button-style($color) {
  background-color: $color;
  font-size: 14px;
  padding: 5px 10px;
  color: white;
  font-weight: bold;
  width: 100px;
  cursor: pointer;
}
.tile__container {
  border: 1px solid black;
  border-radius: 10px;
  width: 25%;
  padding: 1% 2%;
  .add__message {
    color: blue;
  }
  .delete__message {
    color: red;
  }
  .tile__title {
    font-size: 24px;
    font-weight: bold;
  }
  .edit__button {
    margin-top: 15px;
    button {
      @include button-style(#1f6aca);
    }
  }
  .delete__button {
    button {
      @include button-style(red);
    }
  }
}
