.press__add__section {
  .image__display__preview {
    .image__user__input {
      width: 50%;
    }
    .text__user__input {
      padding-top: 20px;
      width: 50%;
      button {
        margin-top: 15px;
        font-size: 22px;
        padding: 10px 15px;
        background-color: #1f6aca;
        color: white;
        font-weight: bold;
        cursor: pointer;
      }
    }
  }
}
