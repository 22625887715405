.homepage__logo__container {
  padding-left: 10%;
  .homepage__logo {
    width: 130px;
    height: 130px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    vertical-align: middle;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      height: auto;
      padding: 5%;
    }
  }
}

.sponsor__text__user__input {
  padding-bottom: 100px;
  button {
    margin-left: 10px;
    font-size: 22px;
    padding: 10px 15px;
    background-color: #1f6aca;
    color: white;
    font-weight: bold;
    cursor: pointer;
  }
}
