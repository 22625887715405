.login__section__container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .logo__section {
    width: 800px;
    height: 500px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .ca__logo__container {
      width: 500px;
      img {
        width: 100%;
        height: auto;
      }
    }
    .login__admin__text {
      form {
        label {
          font-size: 18px;
        }
        input {
          padding: 8px;
          display: block;
          border: none;
          border-bottom: 1px solid #ccc;
          width: 100%;
          font-size: 16px;
        }
        button {
          font-size: 22px;
          padding: 10px 15px;
          background-color: #1f6aca;
          color: white;
          font-weight: bold;
        }
      }
    }
  }
}
