.greeting__container {
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dashboard__container {
  padding: 1% 7%;
}
