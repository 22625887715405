.current__stats {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .single__stat {
    text-align: center;
  }
}

.new__stats__container {
  .custom__input__field {
    width: 20%;
    margin-bottom: 20px;
    label {
      font-weight: bold;
    }
    span {
      color: red;
      font-weight: bold;
    }
  }
  button {
    margin-top: 20px;
    margin-bottom: 50px;
    font-size: 22px;
    padding: 10px 15px;
    background-color: #1f6aca;
    color: white;
    font-weight: bold;
    cursor: pointer;
  }
}
