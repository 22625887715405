.navbar__container {
  display: flex;
  border-bottom: 2px solid black;
  padding: 1% 7%;
  background-color: lightgray;
  .dgac__logo {
    width: 250px;
    img {
      width: 100%;
      height: auto;
    }
  }
  .navigation__buttons__container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .navigation__buttons {
      button {
        margin-left: 10px;
        font-size: 22px;
        padding: 10px 15px;
        background-color: #1f6aca;
        color: white;
        font-weight: bold;
        cursor: pointer;
      }
    }
  }
}
