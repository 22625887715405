.dealers__dashboard .dealers__toggle__views button {
  margin-left: 10px;
  font-size: 22px;
  padding: 10px 15px;
  background-color: #1f6aca;
  color: white;
  font-weight: bold;
  cursor: pointer;
}

input {
  padding: 8px;
  display: block;
  border: none;
  border-bottom: 1px solid #ccc;
  width: 100%;
  font-size: 16px;
}
