.single_sponsor {
  background-color: white;
  margin: 2% 0;
  padding: 3%;
  display: flex;
  .logo_content {
    width: 28%;
    display: flex;
    justify-content: center;
    margin-top: 23px;
    padding-right: 15px;
    a img {
      width: 100%;
      height: auto;
    }
  }
  .single_sponsor_content {
    width: 72%;
  }
  .sponsor_name {
    font-size: 24px;
    font-weight: 500;
    color: #484848;
  }
  .paragraph {
    font-size: 20px;
    color: #505050;
    line-height: 1.4em;
  }
}
