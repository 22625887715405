@mixin button-style {
  font-size: 22px;
  padding: 10px 15px;
  background-color: #1f6aca;
  color: white;
  font-weight: bold;
  cursor: pointer;
}
.dealers__container {
  .editable__dealers {
    display: flex;
    flex-wrap: wrap;
  }
  .submit__section {
    margin-top: 15px;
    button {
      @include button-style();
    }
  }
  .add__dealer__section {
    margin-top: 15px;
    button {
      @include button-style();
    }
  }
}

.loader {
  border: 12px solid #f3f3f3; /* Light grey */
  border-top: 12px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 12px;
  height: 12px;
  animation: spin 2s linear infinite;
}
