.press__tile {
  display: flex;
  align-items: flex-start;
  padding: 20px 20px;
  width: 600px;
  .press__image__container {
    width: 80px;
    img {
      width: 100%;
      height: auto;
      border-radius: 10px;
    }
  }
  .press__info__container {
    padding: 0 2%;
    .press__publisher {
      font-size: 13px;
      color: blue;
    }
    .press__text {
      width: 340px;
      font-size: 18px;
      a {
        text-decoration: none;
        font-weight: bold;
      }
    }
  }
}
@media only screen and (min-width: 320px) and (max-width: 800px) {
  .press__tile {
    padding: 20px 0px;
    width: 400px;
    .press__image__container {
      width: 80px;
      img {
        width: 100%;
        height: auto;
        border-radius: 10px;
      }
    }
    .press__info__container {
      padding: 0 3%;
      .press__publisher {
        font-size: 13px;
        color: blue;
      }
      .press__text {
        width: 220px;
        font-size: 18px;
        a {
          text-decoration: none;
          font-weight: bold;
        }
      }
    }
  }
}
